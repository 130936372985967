// video-player.tsx
"use client";

import React, { forwardRef, useRef } from "react";
import { checkIsHlsMedia, useHlsMedia } from "ui/lib/use-hls-media";

interface VideoPlayerProps extends React.VideoHTMLAttributes<HTMLVideoElement> {
  autoPlay?: boolean;
  loop?: boolean;
  controls?: boolean;
  url: string;
  fallbackUrl?: string;
  className?: string;
  type?: string;
}

export const VideoPlayerWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <div
      className="absolute inset-0 flex-center bg-storm-900"
      style={{ width: 440 }}
    >
      {children}
    </div>
  );
};

interface HlsVideoPlayerProps extends VideoPlayerProps {
  playerRef: React.RefObject<HTMLVideoElement>;
}

const HlsVideoPlayer: React.FC<HlsVideoPlayerProps> = ({
  url,
  playerRef,
  autoPlay = true,
  loop = true,
  controls = false,
  poster,
  fallbackUrl,
  ...rest
}) => {
  const { isNativeSupported, error } = useHlsMedia({
    ref: playerRef,
    url,
    autoPlay,
  });

  if (error) {
    return (
      <div className="error-message">
        <p>Sorry, this video cannot be played in your browser.</p>
      </div>
    );
  }

  return (
    <video
      ref={playerRef}
      width="440"
      height="auto"
      autoPlay={autoPlay}
      controls={controls}
      loop={loop}
      poster={poster}
      {...rest}
    >
      {/* If native HLS is supported, set the src directly */}
      {isNativeSupported ? (
        <source src={url} type="application/vnd.apple.mpegurl" />
      ) : // If using hls.js, do not set src; hls.js will handle it
      null}
      {/* Fallback source for browsers that do not support HLS */}
      {fallbackUrl && <source src={fallbackUrl} type="video/mp4" />}
      Your browser does not support the video tag.
    </video>
  );
};

export const VideoPlayer = forwardRef<HTMLVideoElement, VideoPlayerProps>(
  (
    {
      autoPlay = true,
      loop = true,
      controls = false,
      url,
      className,
      type,
      fallbackUrl,
      poster,
      ...rest
    },
    forwardedRef
  ) => {
    const internalRef = useRef<HTMLVideoElement>(null);
    const playerRef =
      (forwardedRef as React.RefObject<HTMLVideoElement>) || internalRef;

    const isHlsMedia = checkIsHlsMedia(url);
    const { isSupported, isNativeSupported, error } = useHlsMedia({
      ref: playerRef,
      url,
      autoPlay,
    });

    if (error) {
      return (
        <div className="error-message">
          <p>Your browser does not support HLS streaming.</p>
          <p>Please update your browser or try a different one.</p>
        </div>
      );
    }

    if (isHlsMedia) {
      if (isSupported || isNativeSupported) {
        return (
          <HlsVideoPlayer
            loop={loop}
            url={url}
            playerRef={playerRef}
            autoPlay={autoPlay}
            controls={controls}
            className={className}
            fallbackUrl={fallbackUrl}
            poster={poster}
            {...rest}
          />
        );
      } else if (!fallbackUrl) {
        // HLS is not supported, and no fallback is available
        return (
          <div className="error-message">
            <p>Your browser does not support this video.</p>
            <p>Please update your browser or try a different one.</p>
          </div>
        );
      }
    }

    return (
      <video
        ref={playerRef}
        loop={loop}
        width="440"
        height="auto"
        autoPlay={autoPlay}
        controls={controls}
        className={className}
        poster={poster}
        {...rest}
      >
        <source src={url} type={type ?? "video/mp4"} />
        {fallbackUrl && <source src={fallbackUrl} type="video/mp4" />}
        Your browser does not support the video tag.
      </video>
    );
  }
);

VideoPlayer.displayName = "VideoPlayer";
