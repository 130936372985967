function PlayIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.598 16.553c.378 0 .7-.151 1.077-.369l11.02-6.37c.784-.462 1.058-.765 1.058-1.266 0-.5-.274-.803-1.058-1.257L2.675.912C2.297.695 1.976.553 1.598.553c-.7 0-1.134.53-1.134 1.351v13.288c0 .822.435 1.361 1.134 1.361z"
        fill="currentColor"
      />
    </svg>
  );
}

export default PlayIcon;
